import { useEffect } from "react";
import "./RightClickMenu.css"; // CSS file for styling

const RightClickMenu = ({ xPos, yPos, isOpen, onClose, children }) => {
  // Handle click anywhere else in the document and close menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      const menu = document.querySelector(".right-click-menu");
      if (menu && !menu.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  // Calculate the position of the menu based on click coordinates
  const menuStyle = {
    top: yPos,
    left: xPos,
  };

  return (
    <div className="right-click-menu" style={menuStyle}>
      <ul>
        {children.map((child, i) => (
          <div key={i} onClick={onClose}>
            {child}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default RightClickMenu;
