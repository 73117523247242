import React from "react";
import "./Toolbar.css";

const Toolbar = ({ onDownloadClick, onFetchClick, onLogoutClick }) => {
  return (
    <div className="toolbar d-flex justify-content-between align-items-center">
      <div className="toolbar-title">
        <h5>Mi album</h5>
      </div>
      <div className="toolbar-icons">
        <button className="btn btn-link" onClick={onFetchClick}>
          <i className="fas fa-refresh"></i>
        </button>
        <button className="btn btn-link" onClick={onDownloadClick}>
          <i className="fas fa-download"></i>
        </button>
        <button className="btn btn-link" onClick={onLogoutClick}>
          <i className="fa-solid fa-right-from-bracket"></i>
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
