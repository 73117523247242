import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const CreateAlbumModal = ({ albums, onSubmit, onCancel }) => {
  const [title, setTitle] = useState("");
  const [parentId, setParentId] = useState(null);

  const handleSubmit = () => {
    // Assuming onSubmit receives title and parentId
    onSubmit({ title, parentId });
    // Clear inputs after submission
    setTitle("");
    setParentId(null);
  };

  return (
    <Modal show={true} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Create Album</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formParent">
            <Form.Label>Parent</Form.Label>
            <Form.Control
              as="select"
              value={parentId}
              onChange={(e) => setParentId(parseInt(e.target.value))}
            >
              <option value={null}>None</option>
              {albums.map((album) => (
                <option key={album.id} value={album.id}>
                  {album.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateAlbumModal;
