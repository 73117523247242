import React from "react";
import "./LoginCard.css";
import supabase from "./supabase";

const LoginCard = () => {
  const handleGoogleLogin = () => {
    supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: window.location.href,
      },
    });
  };
  const handleAppleLogin = () => {
    supabase.auth.signInWithOAuth({
      provider: "apple",
      options: {
        redirectTo: window.location.href,
      },
    });
  };

  return (
    <div className="login-card-container">
      <div className="login-card">
        <h2 className="text-center mb-4">Login</h2>
        <button className="login-button apple" onClick={handleAppleLogin}>
          <i className="fab fa-apple"></i> Login with Apple
        </button>
        <button className="login-button google" onClick={handleGoogleLogin}>
          <i className="fab fa-google"></i> Login with Google
        </button>
        {/* <button className="login-button facebook" onClick={handleFacebookLogin}>
          <i className="fab fa-facebook"></i> Login with Facebook
        </button>
        <button className="login-button microsoft" onClick={handleMicrosoftLogin}>
          <i className="fab fa-microsoft"></i> Login with Microsoft
        </button> */}
      </div>
    </div>
  );
};

export default LoginCard;
