import React, { useState } from "react";
import axios from "axios";
import JSZip from "jszip";
import supabase from "./supabase";
import config from "./config";

const getAsset = async (photo) => {
  const { data, error } = await supabase.auth.getSession();
  if (error !== null) {
    return;
  }

  const response = await axios.get(config.backendUrl + "/assets/" + photo.id, {
    headers: {
      Authorization: "Bearer " + data.session.access_token,
    },
  });
  return response.data;
};

function randomFileName(length, ext) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result + ext;
}

const Downloader = ({ photos, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [downloadedBytes, setDownloadedBytes] = useState(0);

  const fetchAndZipFiles = async () => {
    setLoading(true);
    setError(null);
    setProgress(0);
    setTotalBytes("?");
    setDownloadedBytes(0);
    const zip = new JSZip();

    try {
      // Calculate the total bytes to be downloaded
      const assetDetailPromises = photos.map(async (photo) => {
        return await getAsset(photo);
      });
      const assetDetails = await Promise.all(assetDetailPromises);

      // TODO: asset detail should give size
      //   const sizePromises = assetDetails.map(async (detail) => {
      //     const response = await axios.head(detail.downloadUrl);
      //     total += parseInt(response.headers["content-length"], 10);
      //   });
      //   await Promise.all(sizePromises);
      //   setTotalBytes(total);

      const totalCount = assetDetails.length;
      let finishedCount = 0;
      await Promise.all(
        assetDetails.map(async (asset) => {
          const response = await axios.get(asset.downloadUrl, {
            responseType: "blob",
            onDownloadProgress: (event) => {
              setDownloadedBytes((prevDownloadedBytes) => {
                const newDownloadedBytes = prevDownloadedBytes + event.loaded;
                return newDownloadedBytes;
              });
            },
          });

          finishedCount += 1;
          setProgress(finishedCount / totalCount);

          if (!asset.fileName) {
            asset.fileName = randomFileName(16, ".jpg");
          }
          zip.file(asset.fileName, response.data);
        })
      );

      const zipBlob = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "downloaded_files.zip";
      link.click();
    } catch (err) {
      setError("Error downloading files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <button style={styles.closeButton} onClick={onClose}>
          X
        </button>
        <h2>Download Files</h2>
        {loading ? (
          <div>
            <progress value={progress} max="100"></progress>
            <p>
              {Math.round(progress)}% - {downloadedBytes} / {totalBytes} bytes
            </p>
          </div>
        ) : (
          <button onClick={fetchAndZipFiles}>Download</button>
        )}
        {error && <p style={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popup: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 10px 25px rgba(0,0,0,0.5)",
    padding: "20px",
    width: "600px",
    textAlign: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    marginTop: "10px",
  },
};

export default Downloader;
