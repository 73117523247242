import { useState, useReducer } from "react";
import "./PhotoList.css";
import Toolbar from "./Toolbar";
import Downloader from "./Downloader";
import Sidebar from "./Sidebar";
import { useApiAssets, useApiAlbums, createAlbum } from "./apiHooks";
import CreateAlbumModal from "./CreateAlbumModal";
import PhotoListItem from "./PhotoListItem";

const PhotoList = ({ handleLogout }) => {
  const { albums, fetchAlbums } = useApiAlbums();
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const { assets } = useApiAssets(currentAlbum, 100);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showDownloader, setShowDownloader] = useState(false);
  const [showCreateAlbumModal, setShowCreateAlbumModal] = useState(false);

  const handleCreateAlbum = ({ title, parentId }) => {
    const innerAsync = async () => {
      await createAlbum({ title, parentAlbum: parentId });
      fetchAlbums();
    };

    innerAsync();
  };
  const closeCreateAlbum = () => {
    setShowCreateAlbumModal(false);
  };

  const createHandleImageClick = (key) => (event) => {
    const photo = assets.find((v) => v.id === key);
    photo.selected = !photo.selected;
    forceUpdate();
  };

  const handleDownloadClick = () => {
    setShowDownloader(true);
  };

  // Render code for album
  const groupedImages = assets.reduce((acc, img) => {
    const year = new Date(img.createdTimestamp).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(img);
    return acc;
  }, {});

  const photoGrid = (
    <div>
      {Object.entries(groupedImages).map(([year, photos]) => (
        <div key={year}>
          <h2 className="year">{year}</h2>
          <div className="photo-grid">
            {photos.map((photo) => (
              <PhotoListItem
                key={photo.id}
                photo={photo}
                handleClick={createHandleImageClick(photo.id)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="layout-wrapper">
      <Toolbar
        onDownloadClick={handleDownloadClick}
        onFetchClick={() => setCurrentAlbum(null)}
        onLogoutClick={handleLogout}
      />
      <div className="layout-container">
        <div className="layout-sidebar">
          <Sidebar
            albums={albums}
            setShowCreateAlbumModal={setShowCreateAlbumModal}
            handleAlbumClick={(album) => {
              setCurrentAlbum(album.id);
            }}
          />
        </div>
        <div className="layout-content">{photoGrid}</div>
      </div>

      {showDownloader && (
        <Downloader
          photos={assets.filter((photo) => photo.selected)}
          onClose={() => setShowDownloader(false)}
        />
      )}
      {showCreateAlbumModal && (
        <CreateAlbumModal
          albums={albums}
          onSubmit={handleCreateAlbum}
          onCancel={closeCreateAlbum}
        />
      )}
    </div>
  );
};

export default PhotoList;
