import { useState } from "react";
import LazyLoadImage from "./LazyImage";
import RightClickMenu from "./RightClickMenu";

const PhotoListItem = ({ photo, handleClick }) => {
  const [menuPosition, setMenuPosition] = useState({ xPos: 0, yPos: 0 });
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setMenuPosition({ xPos: e.clientX, yPos: e.clientY });
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleMove = () => {
    alert("Not implemented");
  };

  const handleDelete = () => {
    alert("Not implemented");
  };

  return (
    <>
      <LazyLoadImage
        src={photo.thumbnail}
        alt={`Photo ${photo.id}`}
        onClick={handleClick}
        selected={photo.selected}
        onContextMenu={handleContextMenu}
      />
      <RightClickMenu
        xPos={menuPosition.xPos}
        yPos={menuPosition.yPos}
        isOpen={isMenuOpen}
        onClose={handleCloseMenu}
      >
        <li onClick={handleMove}>Move to album</li>
        <li style={{ color: "red" }} onClick={handleDelete}>
          Delete
        </li>
      </RightClickMenu>
    </>
  );
};

export default PhotoListItem;
