import React from "react";
import "./Sidebar.css";
import { Button } from "react-bootstrap";

// Function to build tree structure
const buildTree = (list) => {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentAlbum !== null) {
      // if you have a parent, add yourself to the children array of the parent
      list[map[node.parentAlbum]].children.push(node);
    } else {
      // if you don't have a parent, you're a root node
      roots.push(node);
    }
  }

  // Write depth for each node
  const writeDepth = (node, depth) => {
    node.depth = depth;
    node.children.forEach((child) => writeDepth(child, depth + 1));
  };
  roots.forEach((root) => writeDepth(root, 0));

  return roots;
};

const TreeNode = ({ node, handleAlbumClick }) => (
  <div>
    <button
      className="sidebar-button"
      onClick={() => {
        handleAlbumClick(node);
      }}
      style={{ paddingLeft: 12 + node.depth * 20 }}
    >
      {node.title || "Untitled Album"}
    </button>
    {node.children.length > 0 && (
      <div>
        {node.children.map((child) => (
          <TreeNode
            key={child.id}
            node={child}
            handleAlbumClick={handleAlbumClick}
          />
        ))}
      </div>
    )}
  </div>
);

const Tree = ({ data, handleAlbumClick }) => {
  const treeData = buildTree(data);
  return (
    <div>
      {treeData.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          handleAlbumClick={handleAlbumClick}
        />
      ))}
    </div>
  );
};

const Sidebar = ({ albums, handleAlbumClick, setShowCreateAlbumModal }) => {
  return (
    <div>
      <Tree data={albums} handleAlbumClick={handleAlbumClick} />
      <Button variant="secondary" onClick={setShowCreateAlbumModal}>
        Create Album
      </Button>
    </div>
  );
};

export default Sidebar;
