import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const LazyLoadImage = ({ src, alt, onClick, selected, onContextMenu }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust as needed
  });

  useEffect(() => {
    let mounted = true;
    if (inView) {
      if (mounted) {
        setImageSrc(src);
      }
    }
    return () => {
      mounted = false;
    };
  }, [src, inView]);

  return (
    <img
      ref={ref}
      src={imageSrc}
      alt={alt}
      onClick={onClick}
      className={selected ? "selected" : undefined}
      onContextMenu={onContextMenu}
    />
  );
};

export default LazyLoadImage;
