import App from "./App";
import LoginCard from "./LoginCard";
import supabase from "./supabase";
import { useState, useEffect } from "react";

function LoginPortal() {
  // Detect login status
  const [loginState, setLoginState] = useState(null);

  useEffect(() => {
    (async function () {
      const { data, error } = await supabase.auth.getSession();
      if (error !== null) {
        setLoginState(null);
      } else if (data.session === null) {
        setLoginState(data.session);
      } else {
        setLoginState(data.session);
      }
    })();
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setLoginState(null);
  };

  return loginState ? <App handleLogout={handleLogout} /> : <LoginCard />;
}

export default LoginPortal;
