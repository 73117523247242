import React from "react";
import "./DevBar.css";

const DevBar = () => {
  return (
    <>
      <div className="dev-bar">DEVELOPMENT ENVIRONMENT</div>
    </>
  );
};

export default DevBar;
